import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppActions, selectInitialized } from './shared/state';
import { filter, Subject, takeUntil } from 'rxjs';
import { PreloaderService } from './shared/services/preloader/preloader.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit, OnDestroy {

  initialized$ = this.store.select(selectInitialized);
  unsubscribe$ = new Subject<boolean>();

  constructor(
    private store: Store,
    private preloaderService: PreloaderService
  ) {
    this.initialized$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter(initialized => !initialized)
      )
      .subscribe(_ => this.preloaderService.hide());
  }

  ngOnInit() {

    var urlParams = new URLSearchParams(window.location.search);
    var token = urlParams.get('token') || '';

    this.store.dispatch(AppActions.loading({ token }));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next(true);
    this.unsubscribe$.complete();
  }
}
