export class CacheEntry<T> {
  lastUpdated: number;
  expires: number;
  expirationInMinutes;
  sliding: boolean;
  value: T;

  constructor(value: T, expiresInMinutes: number = 60, sliding: boolean = false) {

    const date = new Date();

    this.value = value;
    this.lastUpdated = Number(date);
    this.expires = date.getTime() + expiresInMinutes * 60000;
    this.sliding = sliding;
    this.expirationInMinutes = expiresInMinutes;
  }

  get isExpired(): boolean {
    return !this.expires || this.expires < Number(new Date());
  }
}
