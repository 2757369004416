import { Injectable } from '@angular/core';
import { CacheEntry } from './cache-entry.model';

@Injectable({
	providedIn: 'root'
})
export class CacheService {

  private cacheRoot = 'cache';

	constructor() {}

	get<T>(key: string): T | null {

		const item = localStorage.getItem(this.getCacheKey(key));

		if (!item) {
			return null;
		}

		const entry = JSON.parse(item) as CacheEntry<T>;

		if (!entry || entry.isExpired) {
			this.remove(key);
		}

		if (entry.sliding) {
			this.set(key, entry.value, entry.expirationInMinutes, entry.sliding);
		}

		return entry.value;
	}

	set(key: string, value: any, expiresInMinutes: number = 5, sliding: boolean = false): void {
		const entry = new CacheEntry(value, expiresInMinutes, sliding);

		localStorage.setItem(this.getCacheKey(key), JSON.stringify(entry));
	}

	remove(key: string): void {
		localStorage.removeItem(this.getCacheKey(key));
	}

	clear(): void {
    for(let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if(key && key.startsWith(this.cacheRoot)) {
        localStorage.removeItem(key);
      }
    }
	}

  private getCacheKey(key: string): string {
    return `${this.cacheRoot}.${key}`;
  }
}
