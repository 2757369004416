import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { UserService } from "../services";
import { switchMap, map, catchError, of } from "rxjs";
import { AppActions } from "./app.actions";
import { Router } from "@angular/router";
import { mockUser, ProblemDetails, UserDetails } from "../models";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../../environments/environment";
import { CacheService } from "../cache";

@Injectable()
export class AppEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private toastrService: ToastrService,
    private cacheService: CacheService,
    private router: Router
  ){}

  userDetailsKey = 'nna:scheduler:user';

  loading$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.loading),
      switchMap((action) => {

        // If no token is provided and we are in a mock environment, return the mock user.
        if(!action.token && environment.allowMock) {
          return of(AppActions.loaded({ userDetails: mockUser }));
        }

        // If a token is provided, get the user details from the server and cache them.
        // We check the token first in case the user has multiple emails for scheduling so that the cached user does
        // not block scheduling a different event.
        if(action.token) {
          return this.userService.getUserDetails(action.token).pipe(
            map(userDetails => {
              this.cacheService.set(this.userDetailsKey, userDetails, 60, true);
              return AppActions.loaded({ userDetails });
            }),
            catchError(() => {
              return of(AppActions.raiseError({
                error: new ProblemDetails({ title: 'An error has occurred.', status: 500, detail: 'Error loading user details'}),
                isFatal: true
              }));
            })
          );
        }

        // If no token is provided but we have a cached user, return the cached user.
        var cachedUser = this.cacheService.get<UserDetails>(this.userDetailsKey);

        if(cachedUser) {
          return of(AppActions.loaded({ userDetails: cachedUser }));
        }

        // If no token is provided and no cached user is found, return an error.
        return of(AppActions.raiseError({
          error: new ProblemDetails({ title: 'Unauthorized', status: 401, detail: 'No token provided.' }),
          isFatal: true,
          route: '/error/expired-link'
        }));
      })
    );
  });

  loaded$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.loaded),
      map(_ => this.router.navigate(['/']))
    );
  }, { dispatch: false });

  error$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AppActions.raiseError),
      map(({ error, isFatal, route }) => {
        if (isFatal) {
          this.router.navigate([route ?? '/error']);
        } else {
          this.toastrService.error(error.detail, error.title);
        }
      })
    );
  }, { dispatch: false });
}
